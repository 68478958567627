<template>
  <section>
    <section class="search-container">
      <a-row class="m-t-10">
          <span class="m-r-10">筛选字段:</span>
          <a-input class="m-r-10 search-input" placeholder="请输入" v-model="searchInfo.topicKey" />
          <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
          <!-- /spms/Kanban/plan -->
          <router-link to="/spms/launchPlan/add"><a-button type="primary" class="f-right">投放管理</a-button></router-link>
          <router-link to="/spms/Kanban/add"><a-button type="primary" class="f-right m-r-10">添加</a-button></router-link>
      </a-row>
    </section>
    <a-row type="flex" class="scroll-container m-t-18">
      <a-col class="list-left">
        <li v-for="(item,key) in newsList" :key="key" :class="currentItem.id == item.id ? 'active' : ''" @click="currentItem = item">
          <a class="title">{{item.topic}}</a>
          <a-popconfirm class="del-icon" title="确定删除该列表?" @confirm="del(item.id)"><a-icon type="close" /></a-popconfirm>
          
          
        </li>
      </a-col>
      <a-col class="list-right scroll-container">
        <a-row>
          <h2 class="text-center" style="padding:16px 0;color:#FFFFFF ">{{currentItem.topic}}</h2>
          <a-row v-if="currentItem.resourceType == 1">
            {{currentItem.contentText}}
          </a-row>
          <a-row v-else-if="currentItem.resourceType == 2">
            <a-row v-for="(item,key) in currentItem.fileUrl" :key="key" class="text-center">
              <img width="880px" :src=" item.url" class="m-b-10" />
            </a-row>
          </a-row>

          <a-row v-else class="text-center">
            <a-row >
               <video v-if="currentItem.fileUrl && currentItem.fileUrl.length"  style="width:1000px" autoplay  controls="controls">
                 <source :src="currentItem.fileUrl[0].url" type="video/mp4">
               </video>
            </a-row>
           
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      listInd: 0,
      pageNum:1,
      newsList: [],
      searchInfo:{},
      currentItem:{}
    };
  },
  created(){
    this.queryContentSetList()
  },
  methods: {
    // 看板列表
    queryContentSetList(){
      let params ={...this.searchInfo}
      params.size = 500
      this.$api.queryContentSetList(params).then(res =>{
        if(res.code === 200){
          this.newsList = res.data || []
          this.currentItem = this.newsList.length ? this.newsList[0] : {}
          this.newsList.forEach(item =>{
            if(item.resourceType == 2 || item.resourceType == 4){
              item.fileUrl = item.fileUrl ? JSON.parse(item.fileUrl) : []
            }
            
          })
          
        }
      })
    },
    reset(){
      this.searchInfo = {}
      this.pageNum = 1
      this.queryContentSetList()
    },
    search(){
      this.pageNum = 1
      this.queryContentSetList()
    },
    del(id){
      this.$api.deletePublicize({id}).then(res =>{
        if(res.code === 200){
          this.queryContentSetList()
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>

  .scroll-container{
    height: calc(100% - 58px);
    .list-left{
      width: 220px;
      background: rgba(0,0,0,0.1);
       border-radius: 4px;
      li{
        list-style: none;
        padding: 8px 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        position: relative;
        .title{
          color: #FFFFFF;
          &::before{
            content:'';
            display: inline-block;
            width: 8px;
            height: 8px;
            background: rgba(255,255,255,0.4);
            margin-right: 8px;
            border-radius: 100%;
          }
        }
        .del-icon{
          position:absolute;
          top:0px;
          right: 0;
          font-size: 12px;
          color: #FFFFFF;
          background: rgba(0,0,0,0.3);
          padding: 4px;
          border-radius: 100%;
          z-index: 44;
          display: none;
        }
        &:hover{
          background: rgba(100, 149, 237,0.3);
          .title{
            color: rgb(100, 149, 237);

          }
          .del-icon{
            display: inline-block
          }
        }
        
      }
      .active{
          background: rgba(100, 149, 237,0.2);
          .title{
            color: rgb(100, 149, 237);
          }
        }
    }
    .list-right{
      width: calc(100% - 230px);
      height: 100%;
      margin-left: 10px;
      background: rgba(0,0,0,0.1);
      border-radius: 4px;
      padding: 10px 16px;
    }
  }
</style>